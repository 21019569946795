<template>
	<div class="carousel">
		<div ref="siema" class="siema">
			<slot />
		</div>
		<template v-if="control && length > 1">
			<slot name="prev">
				<button class="arrow left" @click="prev()" />
			</slot>
			<slot name="next">
				<button class="arrow right" @click="next()">
					<el-progress :percentage="progress" type="circle" :show-text="false" :stroke-width="2" :width="indicatorWidth" color="#a98a4c" stroke-linecap="butt" />
				</button>
			</slot>
		</template>
	</div>
</template>

<script>
import Siema from "siema";
export default {
	components: {
		VueProgressPath: () => import("vue-progress-path")
	},
	props: {
		control: Boolean,
		options: {
			type: Object,
			default: () => new Object,
			validator: val => {
				const { interval, duration } = val;
				try {
					if (interval && (interval < 0 || interval < duration)) throw "interval can't be smaller than duration";
				} catch (e) {
					console.error("[carousel props]:", e);
					return false
				}
				return true;
			}
		}
	},
	data() {
		return {
			carousel: null,
			currentSlide: 0,
			length: 0,
			progress: 0
		}
	},
	computed: {
		interval() {
			const { interval } = this.options;
			return interval && interval / 22 || 0;
		},
		indicatorWidth() {
			const { rwd } = this.$root;
			return rwd > 2 ? 30 : rwd < 2 ? 40 : 35;
		}
	},
	methods: {
		init() {
			const length = this.$slots.default && this.$slots.default.length || 0;
			if (length && length != this.length) {
				this.length = length;
				if (length > 1) {
					const vm = this;
					this.carousel = new Siema({
						...this.options,
						selector: this.$refs["siema"],
						onInit: function () {
							vm.onInit(this.currentSlide);
						},
						onChange: function () {
							vm.onChange(this.currentSlide);
						}
					});
				} else if (this.carousel) this.destroy();
			}
		},
		onInit(currentSlide) {
			this.handleAutoPlay();
			this.$emit("init", currentSlide);
		},
		onChange(currentSlide) {
			this.handleAutoPlay();
			this.currentSlide = currentSlide;
			this.$emit("change", currentSlide);
		},
		async handleAutoPlay() {
			const { interval } = this.options;
			if (interval && interval > 0) {
				clearTimeout(this.timeout);
				clearTimeout(this.timeout2);
				await this.test();
				this.timeout = setTimeout(() => {
					this.next();
				}, interval);
			}
		},
		goTo(index) {
			if (this.carousel) this.carousel.goTo(index);
		},
		prev(howManySlides = 1) {
			if (this.carousel) this.carousel.prev(howManySlides);
		},
		next(howManySlides = 1) {
			if (this.carousel) this.carousel.next(howManySlides);
		},
		remove(index) {
			if (this.carousel) this.carousel.remove(index);
		},
		insert(item) {
			if (this.carousel) this.carousel.insert(item);
		},
		prepend(item) {
			if (this.carousel) this.carousel.prepend(item);
		},
		append(item) {
			if (this.carousel) this.carousel.append(item);
		},
		destroy() {
			clearTimeout(this.timeout);
			if (this.carousel) this.carousel.destroy();
		},
		test(progress = 0) {
			this.progress = progress;
			return new Promise((resolve) => {
				if (this.interval) {
					clearTimeout(this.timeout2);
					this.timeout2 = setTimeout(() => {
						if (progress < 100) this.test(progress + 5);
						resolve(progress);
					}, this.interval);
				}
			});
		}
	},
	mounted() {
		this.init();
	},
	updated() {
		this.init();
	},
	beforeDestroy() {
		this.destroy();
	}
}
</script>

<style lang="scss" scoped>
.carousel {
	position: relative;
}
.arrow {
	position: relative;
	width: 30px;
	@media screen and (min-width: 426px) {
		width: 35px;
	}
	@media screen and (min-width: 769px) {
		width: 40px;
	}
	border-radius: 100%;
	padding: 0;
	transition: 0.5s;
	border: 1px solid rgba($color: #fff, $alpha: 0.5);
	background: transparent;
	cursor: pointer;
	outline: none;
	display: block;
	margin: 0;

	&::before {
		content: "";
		display: block;
		padding-top: 100%;
	}

	&::after {
		content: "";
		display: block;
		width: 25%;
		height: 25%;
		border-style: solid;
		border-width: 2px 2px 0 0;
		border-color: white;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	&.right {
		&::after {
			transform: translate(-75%, -50%) rotate(45deg);
		}
	}

	&.left {
		&::after {
			transform: translate(-25%, -50%) rotate(-135deg);
		}
	}

	&:hover {
		background-color: rgba(192, 169, 114, 0.3);
	}

	.el-progress {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.right,
.left {
	position: absolute;
	bottom: 10px; // use px when browser not support rem
	bottom: 0.625rem;
}
.right {
	left: 50%;
	transform: translateX(50%);
	border: none;
}
.left {
	transform: translateX(-150%);
	left: 50%;
}
</style>